/* tslint:disable */
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: any; output: any; }
  /** Custom facet properties in form of dynamic json. */
  JSON: { input: any; output: any; }
  /**
   * Corresponds to a dictionary of attributes in which the values are single values or arrays of strings or booleans
   * {
   *     [key: string]: string | boolean | (string | boolean)[];
   * }
   */
  ProductAttributes: { input: { [key: string]: (string | boolean)[] | string | boolean; }; output: { [key: string]: (string | boolean)[] | string | boolean; }; }
  /**
   * A scale set defines what sizes and / or lengths are available for a specific scale.
   * e.g:
   * {
   *   'EU': {
   *     size: ['28', '30', '32'],
   *     length: ['30', '31', '32'],
   *   },
   *   'US': {
   *     size: ['28-29', '30-31', '32-33'],
   *     length: ['32', '33', '34'],
   *   }
   * }
   */
  ScaleSet: { input: { [key: string]: { [key: string]: string[]; }}; output: { [key: string]: { [key: string]: string[]; }}; }
  ValueByCurrency: { input: any; output: any; }
};

/** Account item view represents information about account items. */
export type Account = {
  /** Account ID. Used in search and communication with BE */
  accountId: Scalars['String']['output'];
  /** Account types ids */
  accountTypeIds?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** Account authorized for consignment order flag */
  authorizedForConsignmentOrder?: Maybe<Scalars['Boolean']['output']>;
  /** Account authorized for future order flag */
  authorizedForFutureOrder?: Maybe<Scalars['Boolean']['output']>;
  /** List of associated brand organisations */
  brandOrganisations?: Maybe<Array<Maybe<BrandOrg>>>;
  /** Account currency. */
  currency?: Maybe<Scalars['String']['output']>;
  /** Domain Id . */
  domainId?: Maybe<Scalars['String']['output']>;
  /** Account ID in erp system, shown in UI */
  externalId: Scalars['String']['output'];
  /** Account features. */
  features: AccountFeatures;
  /** Account name. */
  name: Scalars['String']['output'];
  /** Custom dynamic properties */
  properties?: Maybe<Scalars['JSON']['output']>;
  /** Account ship addresses. */
  shipToAddresses: Array<Address>;
  /** Account sites. */
  sites?: Maybe<Array<Maybe<Site>>>;
};

/** Account countries */
export type AccountCountry = {
  hasStates: Scalars['Boolean']['output'];
  identifier: Scalars['String']['output'];
  isoCode: Scalars['String']['output'];
  name: Scalars['String']['output'];
  states: Array<Maybe<AccountCountryState>>;
};

export type AccountCountryState = {
  countryIsoCode: Scalars['String']['output'];
  identifier: Scalars['String']['output'];
  name: Scalars['String']['output'];
  stateCode: Scalars['String']['output'];
};

/** Delivery address. */
export type AccountFeatures = {
  /** Drop ship feature flag. */
  allowAlternativeAddress: Scalars['Boolean']['output'];
  /** ERP driven drop ship feature flag. */
  erpDrivenAlternativeDeliverAddress: Scalars['Boolean']['output'];
  /** RRP for discount feature flag. */
  useRrpForDiscount: Scalars['Boolean']['output'];
};

export type AccountFilterValue = {
  count?: Maybe<Scalars['Int']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  imageUrl?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

/** Account reviewers */
export type AccountReviewer = {
  avatarSource?: Maybe<Scalars['Int']['output']>;
  displayName: Scalars['String']['output'];
  email: Scalars['String']['output'];
  imageLink: Scalars['String']['output'];
  isBlocked?: Maybe<Scalars['Boolean']['output']>;
  preferredCultureInfo?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['Int']['output']>;
  userId: Scalars['String']['output'];
};

/** Accounts selector paged response. */
export type AccountSelector = {
  /** Account selector externalId. */
  externalId?: Maybe<Scalars['String']['output']>;
  /** Account selector Id. */
  id: Scalars['String']['output'];
  /** Account selector name. */
  name?: Maybe<Scalars['String']['output']>;
};

/** Account Type. */
export type AccountType = {
  /** Account Type id. */
  accountTypeId?: Maybe<Scalars['String']['output']>;
  /** Brand id. */
  brandId?: Maybe<Scalars['String']['output']>;
  /** Account type name. */
  displayName?: Maybe<Scalars['String']['output']>;
  /** Account Type id. */
  id: Scalars['String']['output'];
};

/** Paginated and filtering query for account type. */
export type AccountTypesPageQuery = {
  /** Account ids. */
  accountIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** BrandOrg ids. */
  brandOrgIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Search account types by external ids */
  externalAccountTypesIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Page number. */
  page?: InputMaybe<Scalars['Int']['input']>;
  /** Search by displayName and accountTypeId. */
  search?: InputMaybe<Scalars['String']['input']>;
  /** Amount of account type returned per page. */
  size?: InputMaybe<Scalars['Int']['input']>;
};

/** Account types paged response. */
export type AccountTypesPagedResponse = {
  /** List of Account type. */
  items?: Maybe<Array<Maybe<AccountType>>>;
  /** Total number of Account type. */
  total: Scalars['Int']['output'];
};

/** Account User */
export type AccountUser = {
  /** Account external Id */
  externalId?: Maybe<Scalars['String']['output']>;
  /** Account Id */
  id: Scalars['String']['output'];
  /** Account name */
  name?: Maybe<Scalars['String']['output']>;
  /** Account users */
  users?: Maybe<Array<Maybe<User>>>;
};

/** Account validation results. */
export type AccountValidationResult = {
  /** Account Id */
  accountId: Scalars['String']['output'];
  /** Account address Ids */
  addressIds: Array<Scalars['String']['output']>;
  /** Validation results. */
  validationResults: Array<ValidationResult>;
};

/** Accounts paged response. */
export type AccountsPagedResponse = {
  /** List of accounts. */
  items: Array<Account>;
  /** Total number of accounts. */
  total: Scalars['Int']['output'];
};

/** Paginated and filtering query for accounts. */
export type AccountsQuery = {
  /** Page number. */
  page?: InputMaybe<Scalars['Int']['input']>;
  /** Search query. */
  query?: InputMaybe<Scalars['String']['input']>;
  /** Amount of accounts returned per page. */
  size?: InputMaybe<Scalars['Int']['input']>;
};

/** Paginated and filtering query for accounts selector. */
export type AccountsSelectorPageQuery = {
  /** Account type ids. */
  accountTypeIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** BrandOrg ids. */
  brandOrgIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Search account by externalIds. */
  externalIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Page number. */
  page?: InputMaybe<Scalars['Int']['input']>;
  /** Search account by name and externalId. */
  search?: InputMaybe<Scalars['String']['input']>;
  /** Amount of accounts selector returned per page. */
  size?: InputMaybe<Scalars['Int']['input']>;
};

/** Accounts selector paged response. */
export type AccountsSelectorPagedResponse = {
  /** List of accounts. */
  items: Array<AccountSelector>;
  /** Total number of accounts. */
  total: Scalars['Int']['output'];
};

/** Delivery address. */
export type Address = {
  /** Address account id. */
  accountId: Scalars['String']['output'];
  /** Address id. */
  addressId: Scalars['String']['output'];
  /** Address box number. */
  box?: Maybe<Scalars['String']['output']>;
  /** Address city. */
  city?: Maybe<Scalars['String']['output']>;
  /** Address country name. */
  country: Scalars['String']['output'];
  /** Address country code. */
  countryCode?: Maybe<Scalars['String']['output']>;
  /** Deleted at UTC. */
  deletedAtUtc?: Maybe<Scalars['String']['output']>;
  /** Has delivery items, indicates that accounts for basket have delivery items for the address. */
  hasDeliveryItems?: Maybe<Scalars['Boolean']['output']>;
  /** Address name. */
  name: Scalars['String']['output'];
  /** Address external id. */
  realAddressId?: Maybe<Scalars['String']['output']>;
  /** Address state name. */
  state?: Maybe<Scalars['String']['output']>;
  /** Address state code. */
  stateCode?: Maybe<Scalars['String']['output']>;
  /** Address street. */
  street?: Maybe<Scalars['String']['output']>;
  /** Address zip code. */
  zipCode?: Maybe<Scalars['String']['output']>;
};

/** Alternative address output. */
export type AlternativeAddress = {
  city: Scalars['String']['output'];
  country: Scalars['String']['output'];
  email?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  street1: Scalars['String']['output'];
  street2?: Maybe<Scalars['String']['output']>;
  zipCode: Scalars['String']['output'];
};

/** Alternative address model. */
export type AlternativeAddressInput = {
  city: Scalars['String']['input'];
  country: Scalars['String']['input'];
  email?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  street1: Scalars['String']['input'];
  street2?: InputMaybe<Scalars['String']['input']>;
  zipCode: Scalars['String']['input'];
};

export type BarcodesSearch = {
  id: Scalars['String']['output'];
  product?: Maybe<Product>;
};

/** Collection of grouped basket items. */
export type Basket = {
  /** Allow user to ship in full option */
  allowUserToSpecifyShipOrderInFullForNowOrder?: Maybe<Scalars['Boolean']['output']>;
  alternativeAddress?: Maybe<AlternativeAddress>;
  /** The user who asked for a review */
  assignee?: Maybe<BasketUser>;
  /** The user who's asked for reviewing the basket */
  assigner?: Maybe<BasketUser>;
  /** Basket identifier. */
  basketId: Scalars['String']['output'];
  /** Basket items flat list. */
  basketItems: Array<BasketItem>;
  /** Total values for bulks. */
  bulkTotal: Total;
  /** Indicates when the check order expires. */
  checkOrderExpiryDate?: Maybe<Scalars['DateTime']['output']>;
  /** Order reference number provided by client. */
  clientReferenceNumber?: Maybe<Scalars['String']['output']>;
  /**
   * Brand org defines if delivery of the order can be scheduled for an exact day, or week, or month
   * Its part of product.accountData, but seems to be legit for basket level
   */
  deliveryWindowType: DeliveryWindowType;
  /** Entity tag for polling. */
  etag?: Maybe<Scalars['String']['output']>;
  /** Grouped basket items. */
  groups: Array<BasketRowsGroup>;
  /** Included materials and quantities for the given basket */
  materialsQuantities?: Maybe<Array<BasketQuantity>>;
  /** Order reference number. */
  orderReferenceNumber?: Maybe<Scalars['String']['output']>;
  /** Total values for non-bulks. */
  realTotal: Total;
  /** API Decides by the user viewing the basket */
  reviewNeeded?: Maybe<Scalars['Boolean']['output']>;
  /** Status of review */
  reviewStatus?: Maybe<ReviewStatus>;
  /** Selected shipping option id */
  selectedShippingOptionId?: Maybe<Scalars['String']['output']>;
  /** Shipping options */
  shippingOptions?: Maybe<Array<Maybe<ShippingOption>>>;
  /** Status of the basket. */
  status: BasketStatus;
  /** When the basket is placed */
  submittedTime?: Maybe<Scalars['String']['output']>;
  /** Totals of all child groups. */
  total: Total;
  /** Basket owner user name. */
  userDisplayName: Scalars['String']['output'];
  /** Basket owner user id. */
  userId: Scalars['String']['output'];
  /** Determines ship in full option */
  userSpecifiedGroupedDelivery?: Maybe<Scalars['Boolean']['output']>;
};

export type BasketAccount = {
  /** Account ID */
  accountId: Scalars['String']['output'];
  /** Extended address info */
  address?: Maybe<Address>;
  /** Address ID */
  addressId?: Maybe<Scalars['String']['output']>;
  /** Basket Account ID */
  basketAccountId?: Maybe<Scalars['String']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
};

/** Basket account reference. */
export type BasketAccountAddress = {
  /** Account identifier. */
  accountId: Scalars['String']['input'];
  /** Address identifier. */
  addressId: Scalars['String']['input'];
  /** Alternative address used for drop ship. */
  alternativeAddress?: InputMaybe<AlternativeAddressInput>;
  realAddressId?: InputMaybe<Scalars['String']['input']>;
};

/** Basket account used for MDO basket to represent items belonging to a single door. */
export type BasketAccountDelivery = {
  /** Account id. */
  accountId: Scalars['String']['input'];
  /** Account address id. */
  addressId: Scalars['String']['input'];
  /** Alternative address used for drop ship. */
  alternativeAddress?: InputMaybe<AlternativeAddressInput>;
  /** Order reference number provided by client. */
  clientReferenceNumber?: InputMaybe<Scalars['String']['input']>;
  /** Basket items. */
  items: Array<BasketUpdateItem>;
};

/** Basket row group details for ADDRESS group */
export type BasketAddressRowsGroupDetails = {
  accountId: Scalars['String']['output'];
  addressId: Scalars['String']['output'];
  clientReferenceNumber?: Maybe<Scalars['String']['output']>;
  deletedAtUtc?: Maybe<Scalars['String']['output']>;
  externalAccountId: Scalars['String']['output'];
  realAddressId?: Maybe<Scalars['String']['output']>;
};

/** Basket clear. */
export type BasketCleanRequest = {
  /** Basket Id. */
  basketId: Scalars['String']['input'];
};

export type BasketColumn = DateBasketColumn | DiscountBasketColumn | OrderTypeBasketColumn | PriceBasketColumn | QuantityBasketColumn;

export enum BasketColumnKeys {
  CreatedDate = 'CREATED_DATE',
  DeliveryDate = 'DELIVERY_DATE',
  Discount = 'DISCOUNT',
  DiscountPercentage = 'DISCOUNT_PERCENTAGE',
  DiscountRrp = 'DISCOUNT_RRP',
  DisplayDateFrom = 'DISPLAY_DATE_FROM',
  Gross = 'GROSS',
  ManualDiscount = 'MANUAL_DISCOUNT',
  Markup = 'MARKUP',
  Net = 'NET',
  OrderType = 'ORDER_TYPE',
  Quantity = 'QUANTITY',
  Rrp = 'RRP',
  SimulatedTotal = 'SIMULATED_TOTAL',
  Total = 'TOTAL',
  Value = 'VALUE',
  ValueNet = 'VALUE_NET',
  ValueRrp = 'VALUE_RRP',
  Wsp = 'WSP'
}

/** Basket row group details for DELIVERY group */
export type BasketDeliveryRowsGroupDetails = {
  clientReferenceNumber?: Maybe<Scalars['String']['output']>;
  consignmentEndDate?: Maybe<Scalars['String']['output']>;
  consignmentStartDate?: Maybe<Scalars['String']['output']>;
  deliveryId: Scalars['String']['output'];
  generatedClientReferenceNumber?: Maybe<Scalars['String']['output']>;
  isConsignmentOrder?: Maybe<Scalars['Boolean']['output']>;
  requestedDeliveryDate?: Maybe<Scalars['String']['output']>;
};

/** Basket email recipient. */
export type BasketEmailRecipient = {
  /** Recipient name. */
  displayName?: InputMaybe<Scalars['String']['input']>;
  /** Recipient email. */
  email: Scalars['String']['input'];
};

/** Basket email request. */
export type BasketEmailRequest = {
  /** Email message. */
  message: Scalars['String']['input'];
  /** Email recipients. */
  recipients: Array<BasketEmailRecipient>;
};

/** Sorting types in scope of a group. */
export enum BasketGroupSortType {
  Alphabetical = 'ALPHABETICAL',
  CreatedDate = 'CREATED_DATE',
  Delivery = 'DELIVERY',
  Markup = 'MARKUP',
  Quantity = 'QUANTITY',
  Rrp = 'RRP',
  TotalNet = 'TOTAL_NET',
  TotalRrp = 'TOTAL_RRP',
  TotalWsp = 'TOTAL_WSP',
  Wsp = 'WSP'
}

/** Basket header with order information. */
export type BasketHeader = {
  /** Basket identifier. */
  basketId: Scalars['String']['input'];
  /** Order reference number provided by client. */
  clientReferenceNumber?: InputMaybe<Scalars['String']['input']>;
  /** Order reference number. */
  orderReferenceNumber?: InputMaybe<Scalars['String']['input']>;
  /** Property bag provided by client. */
  propertyBag?: InputMaybe<Scalars['JSON']['input']>;
  /** Selected shipping option id */
  selectedShippingOptionId?: InputMaybe<Scalars['String']['input']>;
  /** User specified grouped delivery. */
  userSpecifiedGroupedDelivery?: InputMaybe<Scalars['Boolean']['input']>;
};

export type BasketItem = {
  /** Basket item account id. */
  accountId: Scalars['String']['output'];
  /** Basket item address id. */
  addressId?: Maybe<Scalars['String']['output']>;
  /** ID for the article of the product */
  articleId: Scalars['String']['output'];
  /** Basket item id. */
  basketItemId: Scalars['String']['output'];
  /** Combined tracking info from source and originInfomation */
  basketItemOrigin?: Maybe<BasketItemOrigin>;
  /** Unique Brand ID in ERP systems. */
  brandId: Scalars['String']['output'];
  /** Confirmed order number. */
  confirmedOrderNumber?: Maybe<Scalars['String']['output']>;
  /** Confirmed quantity. */
  confirmedQuantity?: Maybe<Scalars['Int']['output']>;
  /** Consignment end date */
  consignmentEndDate?: Maybe<Scalars['String']['output']>;
  /** Consignment start date */
  consignmentStartDate?: Maybe<Scalars['String']['output']>;
  /** Basket Item creation date */
  created?: Maybe<Scalars['String']['output']>;
  displayDateFrom?: Maybe<Scalars['DateTime']['output']>;
  displayDateTo?: Maybe<Scalars['DateTime']['output']>;
  /** Unique Product displayed ID. */
  displayedProductId: Scalars['String']['output'];
  /** Expected delivery date. */
  expectedDeliveryDate?: Maybe<Scalars['DateTime']['output']>;
  /** Basket Item inventory id */
  inventoryId?: Maybe<Scalars['String']['output']>;
  /** Basket Item last update date */
  lastUpdated?: Maybe<Scalars['String']['output']>;
  /** ID for the material of the product */
  materialId?: Maybe<Scalars['String']['output']>;
  /** Optional note attached to the item */
  note?: Maybe<Scalars['String']['output']>;
  /** Basket item order type now|future. */
  orderType: OrderType;
  /** Array of IDs for the materials within the prepack */
  prepackMaterialIds?: Maybe<Array<Scalars['String']['output']>>;
  /** Prepack size. Optional. */
  prepackSize?: Maybe<Scalars['Int']['output']>;
  /** Unique Product ID in ERP systems. */
  productId: Scalars['String']['output'];
  /** Type of the product. */
  productType: BasketItemProductType;
  /** Requested delivery date. */
  requestedDeliveryDate: Scalars['DateTime']['output'];
  /** Requested quantity. */
  requestedQuantity: Scalars['Int']['output'];
  /** Prepack size. Optional. */
  title?: Maybe<Scalars['String']['output']>;
};

/** Basket item validation result. */
export type BasketItemCheckOrderResult = {
  /** Basket item id. */
  basketItemId: Scalars['String']['output'];
  /** Confirmed quantity. */
  confirmedQuantity?: Maybe<Scalars['Int']['output']>;
  /** Expected delivery date. */
  expectedDeliveryDate?: Maybe<Scalars['DateTime']['output']>;
  /** Basket item prices. */
  prices?: Maybe<BasketItemPrice>;
  /** Relevant product id. */
  productId: Scalars['String']['output'];
  /** Basket item validation results. */
  validationResults?: Maybe<Array<ValidationResult>>;
};

export type BasketItemOrigin = {
  originInformation?: Maybe<Scalars['String']['output']>;
  source?: Maybe<Scalars['String']['output']>;
};

/** Prices of an basket item. */
export type BasketItemPrice = {
  /** Discounted gross price. */
  discountedGrossPrice?: Maybe<BasketPrice>;
  /** Gross price. */
  grossPrice?: Maybe<BasketPrice>;
  /** Net price. */
  netPrice?: Maybe<BasketPrice>;
  /** Retail price. */
  retailPrice?: Maybe<BasketPrice>;
};

/** Type of products that can be presented in a basket item. */
export enum BasketItemProductType {
  Prepack = 'PREPACK',
  Size = 'SIZE'
}

export type BasketItemSourceInput = {
  type: Scalars['String']['input'];
};

/** Basket item validation result. */
export type BasketItemUpdateResult = {
  /** Basket item id, used to update existing basket items. */
  basketItemId?: Maybe<Scalars['String']['output']>;
  /** Brand identifier. */
  brandId: Scalars['String']['output'];
  /** Consignment End Date. */
  consignmentEndDate?: Maybe<Scalars['String']['output']>;
  /** Consignment Start Date. */
  consignmentStartDate?: Maybe<Scalars['String']['output']>;
  displayDateFrom?: Maybe<Scalars['DateTime']['output']>;
  displayDateTo?: Maybe<Scalars['DateTime']['output']>;
  /** Inventory Id. */
  inventoryId?: Maybe<Scalars['String']['output']>;
  /** Basket item note. */
  note?: Maybe<Scalars['String']['output']>;
  /** Basket item order type now|future. */
  orderType?: Maybe<OrderType>;
  /** Product identifier. */
  productId: Scalars['String']['output'];
  /** Requeued delivery date for future orders. */
  requestedDeliveryDate?: Maybe<Scalars['String']['output']>;
  /** Requested quantity for basket item. */
  requestedQuantity: Scalars['Int']['output'];
  /** Basket items validation results. */
  validationResults?: Maybe<Array<ValidationResult>>;
};

export type BasketLineThumbnail = ITotalProductItem & {
  /** Basket item id. */
  basketItemIds: Array<Scalars['String']['output']>;
  /** Brand id. */
  brandId: Scalars['String']['output'];
  /** Image of material. */
  imageUrl: Scalars['String']['output'];
  prepack?: Maybe<BasketRowProductPrepack>;
  previewItems: Array<PreviewItem>;
  /** Product Id. */
  productId: Scalars['String']['output'];
  /** Quantity of basket items of material. */
  quantity: Scalars['Int']['output'];
  /** Title of the product. */
  title: Scalars['String']['output'];
};

export type BasketManagerAccount = {
  accountId: Scalars['String']['output'];
  domainId?: Maybe<Scalars['String']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type BasketManagerAddress = {
  account: BasketManagerAccount;
  addressId?: Maybe<Scalars['String']['output']>;
  box?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  countryCode?: Maybe<Scalars['String']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  isDropship: Scalars['Boolean']['output'];
  name?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  stateCode?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
  zipCode?: Maybe<Scalars['String']['output']>;
};

export type BasketManagerBrand = {
  id: Scalars['String']['output'];
  logoUrl?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** Filtering information for basket manager requests. */
export type BasketManagerFilterQuery = {
  /** Account to filter. */
  account?: InputMaybe<Scalars['String']['input']>;
  /** Account to filter. */
  accounts?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Brands to filter. */
  brand?: InputMaybe<Scalars['String']['input']>;
  /** Users to filter. */
  createdDateFrom?: InputMaybe<Scalars['String']['input']>;
  /** Users to filter. */
  createdDateTo?: InputMaybe<Scalars['String']['input']>;
  /** Configurability for the filters */
  includedFilters: Array<InputMaybe<BasketManagerFilters>>;
  /** Order type to filter. */
  orderType?: InputMaybe<Scalars['String']['input']>;
  /** Search query. */
  query?: InputMaybe<Scalars['String']['input']>;
  /** Basket status to filter. */
  status?: InputMaybe<BasketStatus>;
  /** Users to filter. */
  user?: InputMaybe<Scalars['String']['input']>;
};

/** Response containing all filter values. */
export type BasketManagerFilterValues = {
  accountFilter: Array<AccountFilterValue>;
  brandFilter: Array<SimpleFilterValue>;
  orderTypeFilter: Array<SimpleFilterValue>;
  statusFilter: Array<SimpleFilterValue>;
  userFilter: Array<SimpleFilterValue>;
};

export enum BasketManagerFilters {
  Account = 'Account',
  Brand = 'Brand',
  DatesRange = 'DatesRange',
  OrderType = 'OrderType',
  Status = 'Status',
  User = 'User'
}

/** Basket without item information */
export type BasketManagerInfo = {
  /** Basket accounts */
  basketAccounts?: Maybe<Array<Maybe<BasketAccount>>>;
  basketId: Scalars['String']['output'];
  /** Brands in basket */
  brands?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** Custom reference number or name */
  clientReferenceNumber?: Maybe<Scalars['String']['output']>;
  /** Basket's creator */
  createdBy?: Maybe<BasketUser>;
  /** Basket creation time */
  creationTime: Scalars['DateTime']['output'];
  /** Basket last updated time */
  lastUpdatedTime?: Maybe<Scalars['DateTime']['output']>;
  orderReferenceNumber: Scalars['String']['output'];
  /** Status of the basket */
  status?: Maybe<Scalars['String']['output']>;
  /** Basket Summary */
  summary?: Maybe<Summary>;
  /** Basket User */
  user?: Maybe<BasketUser>;
};

export type BasketManagerItem = {
  addresses: Array<BasketManagerAddress>;
  basketId: Scalars['String']['output'];
  brands: Array<BasketManagerBrand>;
  checkOrderExpiryUtc?: Maybe<Scalars['DateTime']['output']>;
  clientReferenceNumber?: Maybe<Scalars['String']['output']>;
  createdBy: UserExtended;
  currency?: Maybe<Scalars['String']['output']>;
  hasDiscount: Scalars['Boolean']['output'];
  isMerged: Scalars['Boolean']['output'];
  lastUpdatedUtc?: Maybe<Scalars['DateTime']['output']>;
  net: Scalars['Float']['output'];
  orderReferenceNumber?: Maybe<Scalars['String']['output']>;
  orderType?: Maybe<OrderType>;
  quantity: Scalars['Int']['output'];
  rrp: Scalars['Float']['output'];
  rrpCurrency?: Maybe<Scalars['String']['output']>;
  status?: Maybe<BasketStatus>;
  submittedBy?: Maybe<UserExtended>;
  value: Scalars['Float']['output'];
  wsp: Scalars['Float']['output'];
  wspCurrency?: Maybe<Scalars['String']['output']>;
};

/** Basket manager list request. */
export type BasketManagerListRequest = {
  /** Is the sorting ascending or descending. */
  ascending?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filtering data. */
  filters?: InputMaybe<BasketManagerFilterQuery>;
  /** Which property to order by. */
  orderBy?: InputMaybe<BasketManagerOrderByType>;
  /** Which page to get. */
  page?: InputMaybe<Scalars['Int']['input']>;
  /** How many items per page. */
  size?: InputMaybe<Scalars['Int']['input']>;
};

/** Types or sorting for basket manager. */
export enum BasketManagerOrderByType {
  ClientReferenceNumber = 'ClientReferenceNumber',
  LastUpdatedUtc = 'LastUpdatedUtc',
  OrderReferenceNumber = 'OrderReferenceNumber',
  Quantity = 'Quantity',
  Value = 'Value'
}

/** Response containing list of requested items. */
export type BasketManagerResult = {
  data: Array<BasketManagerItem>;
  page: Scalars['Int']['output'];
  size: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

/** Basket Mapping Settings */
export type BasketMappingConfig = {
  additionalPrefix?: InputMaybe<Scalars['String']['input']>;
  basketItemCategoryAttributeKey?: InputMaybe<Scalars['String']['input']>;
  defaultGroupingName?: InputMaybe<Scalars['String']['input']>;
  productTitleLevel?: InputMaybe<Scalars['Int']['input']>;
  productTitleLevelString?: InputMaybe<Scalars['String']['input']>;
};

/** Represents one of prices of basket item. */
export type BasketPrice = {
  /** Currency. */
  currency: Scalars['String']['output'];
  /** Price. */
  price?: Maybe<Scalars['Float']['output']>;
  /** Unit price. */
  unitPrice?: Maybe<Scalars['Float']['output']>;
};

/** Basket quantity entity */
export type BasketQuantity = {
  /** Amount */
  amount: Scalars['Int']['output'];
  /** Brand identifier */
  brandId?: Maybe<Scalars['String']['output']>;
  /** Product identifier */
  productId: Scalars['String']['output'];
  /** Product type */
  productType: Scalars['String']['output'];
};

/**
 * Basket row view represents information about basket items.
 * In DB each basket item is linked to a sellable product (size or prepack).
 * On UI we operate with materials or prepacks.
 * Prepacks can not be grouped by materials because they may contain multiple materials inside.
 */
export type BasketRow = {
  /** List of associated basket items. */
  basketItems: Array<BasketItem>;
  /** Total values for bulks. */
  bulkTotal: Total;
  columns: Array<BasketColumn>;
  /** Creation date of the first item */
  created?: Maybe<Scalars['String']['output']>;
  /** Respective delivery entity */
  delivery: BasketDeliveryRowsGroupDetails;
  /** Image of the material. */
  imageUrl: Scalars['String']['output'];
  /** Date when any item was updated */
  lastUpdated?: Maybe<Scalars['String']['output']>;
  materialWidthIds?: Maybe<Array<Maybe<MaterialWidth>>>;
  previewItems: Array<PreviewItem>;
  /** Price information that is related with the row. */
  priceInfo: BasketRowPrice;
  /** Product associated with the basket row. */
  product: BasketRowProduct;
  /** Total values for non-bulks. */
  realTotal: Total;
  /**
   * Total values.
   * rrp = RRP * Quantity.
   * wsp = (WSPD ?? WSP) * Quantity.
   */
  total: Total;
};

/** Prices that are related with a basket row. */
export type BasketRowPrice = {
  /** Currency. */
  currency?: Maybe<Scalars['String']['output']>;
  /** Rrp Currency. */
  currencyRrp?: Maybe<Scalars['String']['output']>;
  /**
   * Discount rate from 0 to 1. Zero means no discount.
   * discountRate = 1 - (wspd ?? wsp)/wsp.
   */
  discountRate?: Maybe<Scalars['Float']['output']>;
  /** Displayed WSP. */
  displayedwsp?: Maybe<Scalars['Float']['output']>;
  /** Manual Discount Rate */
  manualDiscount?: Maybe<Scalars['Float']['output']>;
  /** The ratio between the WSP and the RRP. */
  markUp?: Maybe<Scalars['Float']['output']>;
  /** Net price. */
  net?: Maybe<Scalars['Float']['output']>;
  /** Recommended retail price. */
  rrp?: Maybe<Scalars['Float']['output']>;
  /**
   * Wholesale price.
   * Gross price.
   */
  wsp?: Maybe<Scalars['Float']['output']>;
  /**
   * Discounted WSP.
   * Optional.
   */
  wspd?: Maybe<Scalars['Float']['output']>;
};

/** Basket row product. */
export type BasketRowProduct = {
  /** Id of the article this product belongs to */
  articleId: Scalars['String']['output'];
  /** Title of the article this product belongs to */
  articleTitle: Scalars['String']['output'];
  /** Unique Brand ID in ERP systems. */
  brandId: Scalars['String']['output'];
  /** Color */
  color: Scalars['String']['output'];
  /** Color code */
  colorCode: Scalars['String']['output'];
  /** Id of the article formatted removing prefix. */
  displayedArticleId?: Maybe<Scalars['String']['output']>;
  /** Unique Product displayed ID. */
  displayedProductId: Scalars['String']['output'];
  /** Is closed out product */
  isclosedoutproduct?: Maybe<Scalars['Boolean']['output']>;
  prepack?: Maybe<BasketRowProductPrepack>;
  /** Unique Product ID in ERP systems. */
  productId: Scalars['String']['output'];
  /** Product type. */
  productType: BasketRowProductType;
  /** Title of the product. */
  title: Scalars['String']['output'];
};

export type BasketRowProductPrepack = {
  /** Id of the brand */
  brandId: Scalars['String']['output'];
  /** Identifier of the prepack as a sellable product */
  productId: Scalars['String']['output'];
  /** Quantities per product contained in the prepack. */
  quantities: Array<PrepackUnitItem>;
};

/** Type of products that can be presented in a basket row. */
export enum BasketRowProductType {
  Material = 'MATERIAL',
  Prepack = 'PREPACK'
}

/** Generic group for basket items. */
export type BasketRowsGroup = {
  /** Totals of bulk child entries. */
  bulkTotal: Total;
  columns: Array<BasketColumn>;
  /** Creation date of the first item */
  created?: Maybe<Scalars['String']['output']>;
  /** Group details */
  details?: Maybe<BasketRowsGroupDetailsUnion>;
  /** Nested groups if presented. Optional. */
  groups?: Maybe<Array<BasketRowsGroup>>;
  /**
   * ID of an entity that represents the group.
   * It can be `catalog`, `delivery date`, or `product id`.
   */
  id: Scalars['String']['output'];
  /** Image of the group */
  imageUrl?: Maybe<Scalars['String']['output']>;
  /** Date when any item was updated */
  lastUpdated?: Maybe<Scalars['String']['output']>;
  /** Totals of non-bulk child entries. */
  realTotal: Total;
  /**
   * Items it the group.
   * Empty if `childGroups` field is presented.
   */
  rows?: Maybe<Array<BasketRow>>;
  /** Subtitle of the group. */
  subTitle?: Maybe<Scalars['String']['output']>;
  thumbnails: Array<BasketLineThumbnail>;
  /** Display name of the group. */
  title: Scalars['String']['output'];
  /** Totals of all child entries. */
  total: Total;
  /** Type of the group. */
  type: BasketRowsGroupType;
};

/** Union for group details */
export type BasketRowsGroupDetailsUnion = BasketAddressRowsGroupDetails | BasketDeliveryRowsGroupDetails;

/** Types of basket items grouping. */
export enum BasketRowsGroupType {
  Address = 'ADDRESS',
  Article = 'ARTICLE',
  Articlegroup = 'ARTICLEGROUP',
  Brand = 'BRAND',
  Businessunit = 'BUSINESSUNIT',
  Category = 'CATEGORY',
  Delivery = 'DELIVERY',
  Division = 'DIVISION',
  OrderNumber = 'ORDER_NUMBER'
}

/** Paginated result for lightweight basket model */
export type BasketSearchResult = {
  baskets?: Maybe<Array<Maybe<BasketManagerInfo>>>;
  totalResultCount?: Maybe<Scalars['Int']['output']>;
};

export enum BasketSearchSortDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export enum BasketSearchSortType {
  LastUpdatedTime = 'LAST_UPDATED_TIME',
  OrderReferenceNumber = 'ORDER_REFERENCE_NUMBER',
  SubmittedDateTime = 'SUBMITTED_DATE_TIME',
  TotalPiecesQuantity = 'TOTAL_PIECES_QUANTITY'
}

/** Basket basic informaion */
export type BasketSlim = {
  basketAccounts?: Maybe<Array<Maybe<BasketAccount>>>;
  clientReferenceNumber?: Maybe<Scalars['String']['output']>;
  createdTime: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  /** Total Basket Net Value per Currency */
  netTotalValueByCurrency?: Maybe<Scalars['ValueByCurrency']['output']>;
  orderReferenceNumber: Scalars['String']['output'];
  reviewStatus?: Maybe<ReviewStatus>;
  status?: Maybe<Scalars['String']['output']>;
  /** Total Basket product pieces count */
  totalQuantity: Scalars['Int']['output'];
  /** Total Basket Value per Currency */
  totalValueByCurrency?: Maybe<Scalars['ValueByCurrency']['output']>;
};

export enum BasketStatus {
  Accepted = 'ACCEPTED',
  Acknowledged = 'ACKNOWLEDGED',
  /** Submit failed. */
  Failed = 'FAILED',
  New = 'NEW',
  PaymentFailed = 'PAYMENT_FAILED',
  PaymentInitiated = 'PAYMENT_INITIATED',
  PaymentSuccess = 'PAYMENT_SUCCESS',
  /** Adding type to be used in review baskets */
  Pending = 'PENDING',
  Processed = 'PROCESSED',
  /** Beginning processing */
  Processing = 'PROCESSING',
  Rejected = 'REJECTED',
  Submitted = 'SUBMITTED'
}

/** Basket item. */
export type BasketUpdateItem = {
  /** Basket item id, used to update existing basket items. */
  basketItemId?: InputMaybe<Scalars['String']['input']>;
  /** Brand identifier. */
  brandId: Scalars['String']['input'];
  /** Consignment End Date. */
  consignmentEndDate?: InputMaybe<Scalars['String']['input']>;
  /** Consignment Start Date. */
  consignmentStartDate?: InputMaybe<Scalars['String']['input']>;
  displayDateFrom?: InputMaybe<Scalars['String']['input']>;
  displayDateTo?: InputMaybe<Scalars['String']['input']>;
  /** Inventory Id. */
  inventoryId?: InputMaybe<Scalars['String']['input']>;
  /** Basket item note. */
  note?: InputMaybe<Scalars['String']['input']>;
  /** Basket item order type now|future. */
  orderType: OrderType;
  /** Tracking information for basket item, if empty BE merges the exising originInformation */
  originInformation?: InputMaybe<Scalars['JSON']['input']>;
  /** Product identifier. */
  productId: Scalars['String']['input'];
  /** Requested delivery date for future orders. */
  requestedDeliveryDate?: InputMaybe<Scalars['String']['input']>;
  /** Requested quantity for basket item. */
  requestedQuantity: Scalars['Int']['input'];
  /** Tracking information for basket product, if empty BE merges the exising source */
  source: BasketItemSourceInput;
};

/** Basket update model. */
export type BasketUpdateRequest = {
  /** Basket header. */
  basketHeader: BasketHeader;
  /** Deliveries for the basket. */
  deliveries?: InputMaybe<Array<Delivery>>;
  /** Basket delivery items per account address. */
  deliveryItems: Array<BasketAccountDelivery>;
};

/** Basket update result. */
export type BasketUpdateResult = {
  /** Basket items validation results. */
  items?: Maybe<Array<BasketItemUpdateResult>>;
  /** Determines ship in full option */
  userSpecifiedGroupedDelivery?: Maybe<Scalars['Boolean']['output']>;
  /** Basket validation results. */
  validationResults?: Maybe<Array<ValidationResult>>;
};

/** Type for assignee/assigner */
export type BasketUser = {
  avatarSource?: Maybe<Scalars['Int']['output']>;
  displayName: Scalars['String']['output'];
  email: Scalars['String']['output'];
  imageLink?: Maybe<Scalars['String']['output']>;
  isBlocked?: Maybe<Scalars['Boolean']['output']>;
  preferredCultureInfo?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['Int']['output']>;
  userId: Scalars['String']['output'];
};

/** Brand organization model. */
export type BrandOrg = {
  /** Brand id. */
  brandId: Scalars['String']['output'];
  /** Brand Organisation Id */
  brandOrganisationId: Scalars['String']['output'];
  /** Display name */
  displayName: Scalars['String']['output'];
  /** Internal Id. */
  id: Scalars['String']['output'];
  /** Is Enabled */
  isEnabled?: Maybe<Scalars['Boolean']['output']>;
  siteId?: Maybe<Scalars['String']['output']>;
};

/** Paginated and filtering query for users. */
export type BrandOrgsPageQuery = {
  /** Account ids. */
  accountIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Account type ids. */
  accountTypeIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Search brandOrgs by externalIds. */
  externalBrandOrgIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Page number. */
  page?: InputMaybe<Scalars['Int']['input']>;
  /** Search by displayName and brandOrganisationId. */
  search?: InputMaybe<Scalars['String']['input']>;
  /** Amount of users returned per page. */
  size?: InputMaybe<Scalars['Int']['input']>;
};

/** Users paged response. */
export type BrandOrgsPagedResponse = {
  /** List of BrandOrg. */
  items?: Maybe<Array<Maybe<BrandOrg>>>;
  /** Total number of brandOrg. */
  total: Scalars['Int']['output'];
};

/** Basket validation result. */
export type CheckOrderResult = {
  /** Basket account validation results. */
  accountValidationResults?: Maybe<Array<AccountValidationResult>>;
  /** Basket items validation results. */
  items?: Maybe<Array<BasketItemCheckOrderResult>>;
  /** Basket validation results. */
  validationResults?: Maybe<Array<ValidationResult>>;
};

export type DabData = {
  /** Basket product items quantity */
  basketItemsQuantity?: Maybe<Scalars['Int']['output']>;
  /** Brand Id */
  brandId: Scalars['String']['output'];
  /** Delivered product items quantity */
  deliveredItemsQuantity?: Maybe<Array<Scalars['Int']['output']>>;
  /** Delivered quantity break down by sizes */
  deliveredSizes?: Maybe<Array<Maybe<DabSize>>>;
  /** Flag indicates inbound items icon visibility */
  hasInboundItemsIcon?: Maybe<Scalars['Boolean']['output']>;
  /** Inbound product items quantity */
  inboundItemsQuantity?: Maybe<Array<Scalars['Int']['output']>>;
  /** Inbound quantity break down by sizes */
  inboundSizes?: Maybe<Array<Maybe<DabSize>>>;
  /** Product Id */
  productId: Scalars['String']['output'];
  rootId?: Maybe<Scalars['String']['output']>;
};

export type DabHistory = {
  brandId: Scalars['String']['output'];
  color: Scalars['String']['output'];
  deliveryDate: Scalars['String']['output'];
  order: Scalars['String']['output'];
  productId: Scalars['String']['output'];
  quantity: Scalars['Int']['output'];
  title: Scalars['String']['output'];
  type: DabHistoryType;
};

export enum DabHistoryType {
  Delivered = 'Delivered',
  Inbound = 'Inbound'
}

/** Product dab query, returns dab data based on passed products data */
export type DabQuery = {
  products?: InputMaybe<Array<ProductKey>>;
  useLegacyAggregation?: InputMaybe<Scalars['Boolean']['input']>;
};

export type DabSize = {
  brandId: Scalars['String']['output'];
  productId: Scalars['String']['output'];
  quantity: Scalars['Int']['output'];
};

export type DateBasketColumn = {
  actualDate?: Maybe<Scalars['String']['output']>;
  date?: Maybe<Scalars['String']['output']>;
  hidden?: Maybe<Scalars['Boolean']['output']>;
  key: BasketColumnKeys;
  overwritten?: Maybe<Scalars['Boolean']['output']>;
  primaryValue?: Maybe<Scalars['String']['output']>;
  secondaryValue?: Maybe<Scalars['String']['output']>;
};

/** Date Range represented by ISO 8601 date strings with UTC */
export type DateRange = {
  /** End of the range */
  endDate?: Maybe<Scalars['DateTime']['output']>;
  /** Start of the range */
  startDate?: Maybe<Scalars['DateTime']['output']>;
};

/** Represents a delivery for a basket. */
export type Delivery = {
  /** Basket validation results. */
  clientReferenceNumber?: InputMaybe<Scalars['String']['input']>;
  /** ID of the delivery. */
  deliveryId: Scalars['String']['input'];
};

/** Types of delivery window. */
export enum DeliveryWindowType {
  Day = 'DAY',
  Month = 'MONTH',
  Week = 'WEEK'
}

export type DescriptionAttribute = {
  /** Display name, to show as a label */
  displayName: Scalars['String']['output'];
  /** Name of the attribute property to use as a description */
  key: Scalars['String']['output'];
};

export type DiscountBasketColumn = {
  actual?: Maybe<Scalars['Float']['output']>;
  discount?: Maybe<DiscountValue>;
  hidden?: Maybe<Scalars['Boolean']['output']>;
  key: BasketColumnKeys;
  overwritten?: Maybe<Scalars['Boolean']['output']>;
  primaryValue?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

export type DiscountValue = {
  percentage?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

/** Search filter options. */
export type Filter = {
  /** Defines if filter can be collapsed in dropdown. */
  collapse?: Maybe<FilterCollapseConfig>;
  /** Defines the data type of the filter, when this is provided we add it to the query params */
  dataType?: Maybe<Scalars['String']['output']>;
  /** Defines filtering option display name. */
  displayName: Scalars['String']['output'];
  /** Defines filtering option display name transform logic. */
  displayValueTransform?: Maybe<FilterDisplayValueTransformOption>;
  /** Defines if default filter values is optional. */
  emptyInitialValues?: Maybe<Scalars['Boolean']['output']>;
  /** Defines filtering field. */
  fieldId: Scalars['String']['output'];
  /** Defines if filter values are facets. */
  isFacet: Scalars['Boolean']['output'];
  /** Defines if multiple filter values selection is allowed. */
  isMultiple: Scalars['Boolean']['output'];
  /** Defines the max amount of multiple values */
  maxAmountOfValues?: Maybe<Scalars['Int']['output']>;
  /** Defines if filter values should be merged into one. */
  mergeValues?: Maybe<Scalars['Boolean']['output']>;
  /** Defines filter option order. */
  order: Scalars['Int']['output'];
  /** Array of subfilters for grouped filters. */
  subFilters?: Maybe<Array<Filter>>;
  /** Defines filter translation key. */
  translationKey?: Maybe<Scalars['String']['output']>;
  /** Defines filter type. */
  type: FilterType;
  /** Array of filter values. */
  values?: Maybe<Array<FilterValueUnion>>;
};

export type FilterCollapseConfig = {
  enabled?: Maybe<Scalars['Boolean']['output']>;
  linkedFields?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  opened?: Maybe<Scalars['Boolean']['output']>;
};

export enum FilterDisplayValueTransformOption {
  Color = 'Color',
  NoTransform = 'NoTransform',
  TitleCase = 'TitleCase',
  UpperCase = 'UpperCase'
}

export type FilterQuery = {
  filters?: InputMaybe<Array<InputMaybe<FilterQueryItem>>>;
  pagination?: InputMaybe<PaginationQuery>;
  partitioning?: InputMaybe<PartitionFilter>;
  search?: InputMaybe<Scalars['String']['input']>;
};

/** Search filter options. */
export type FilterQueryItem = {
  /** Defines filtering field. */
  fieldId: Scalars['String']['input'];
  /** Array of filter values. */
  values?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** Search filter field type. */
export enum FilterType {
  /** Type for date range. */
  DateRange = 'DateRange',
  Group = 'Group',
  Mapped = 'Mapped',
  /** Type for price range. */
  PriceRange = 'PriceRange',
  /** Type for price range. */
  Range = 'Range',
  Value = 'Value'
}

/** Filter value type. */
export type FilterValue = {
  /** Defines filter value facet hit count. */
  count?: Maybe<Scalars['Int']['output']>;
  /** Defines filter value display name. */
  displayName: Scalars['String']['output'];
  /** Defines filter value is disabled. */
  isDisabled?: Maybe<Scalars['Boolean']['output']>;
  /** Defines filter type. */
  type: FilterType;
  /** Defines filter value. */
  value: Scalars['String']['output'];
};

export type FilterValueUnion = FilterValue | RangeFilterValue;

/** Range of floats with min and max */
export type FloatRange = {
  /** Range currency */
  currency?: Maybe<Scalars['String']['output']>;
  /** Upper limit of the range */
  max?: Maybe<Scalars['Float']['output']>;
  /** Lower limit of the range */
  min?: Maybe<Scalars['Float']['output']>;
};

export type GenericProductPrice = {
  contribution?: Maybe<Scalars['Int']['output']>;
  contributionCurrency?: Maybe<Scalars['String']['output']>;
  markup?: Maybe<Scalars['Float']['output']>;
  recommendedRetailPriceCurrency?: Maybe<Scalars['String']['output']>;
  recommendedRetailPriceMax?: Maybe<Scalars['Float']['output']>;
  recommendedRetailPriceMin?: Maybe<Scalars['Float']['output']>;
  wholeSalePriceCurrency?: Maybe<Scalars['String']['output']>;
  wholeSalePriceDiscountedCurrency?: Maybe<Scalars['String']['output']>;
  wholeSalePriceMax?: Maybe<Scalars['Float']['output']>;
  wholeSalePriceMin?: Maybe<Scalars['Float']['output']>;
};

/** Grouped basket. */
export type GroupedBasket = {
  /** Grouped Baskets collection. */
  basket: Basket;
  /** Basket identifier. */
  groupType: BasketRowsGroupType;
};

/** Collection of grouped basket items. */
export type GroupedBasketsResult = {
  /** Basket identifier. */
  basketId: Scalars['String']['output'];
  /** Grouped Baskets collection. */
  groupedBaskets?: Maybe<Array<GroupedBasket>>;
};

/** Aggregated product item data. */
export type ITotalProductItem = {
  /** Basket item id. */
  basketItemIds: Array<Scalars['String']['output']>;
  /** Brand id. */
  brandId: Scalars['String']['output'];
  /** Image of material. */
  imageUrl: Scalars['String']['output'];
  /** Product Id. */
  productId: Scalars['String']['output'];
  /** Quantity of basket items of material. */
  quantity: Scalars['Int']['output'];
  /** Title of the product. */
  title: Scalars['String']['output'];
};

export type InventoryItem = {
  /** Available from date (from when the product is available for delivery) */
  availableFromDateUtc: Scalars['String']['output'];
  /** Available to date (until when the product is available for delivery) */
  availableToDateUtc: Scalars['String']['output'];
  /** Display from date (display from date managed manually by ERP) */
  displayFromDateUtc?: Maybe<Scalars['String']['output']>;
  /** Display to date (display to date managed manually by ERP) */
  displayToDateUtc?: Maybe<Scalars['String']['output']>;
  /** Inventory External Id */
  inventoryExternalId?: Maybe<Scalars['String']['output']>;
  /** External Id of the Inventory, from the ERP */
  inventoryId: Scalars['String']['output'];
  /** Inventory for consignment */
  isConsignment?: Maybe<Scalars['Boolean']['output']>;
  /** Inventory for display only (MOP Warehouse) */
  isNotConsumable?: Maybe<Scalars['Boolean']['output']>;
  /** Is Preferred Noos Warehouse - indicates that this inventory will be faster delivery */
  isPreferredNoosWarehouse?: Maybe<Scalars['Boolean']['output']>;
  /** unique identifier of the inventory */
  itemId: Scalars['String']['output'];
  /** Order type for this Inventory (e.g. Now/Future) */
  orderType: OrderType;
  /** The available quantity (null = infinite quantity) */
  quantity?: Maybe<Scalars['Int']['output']>;
  /** Valid from date (from when this inventory item is valid for purchasing) */
  validFromDateUtc?: Maybe<Scalars['String']['output']>;
  /** Valid to date (until when this inventory item is valid for purchasing) */
  validToDateUtc?: Maybe<Scalars['DateTime']['output']>;
};

export type MaterialWidth = {
  id: Scalars['String']['output'];
  width: Scalars['String']['output'];
};

/** Available media types */
export enum MediaType {
  Image = 'IMAGE',
  Image360 = 'IMAGE360',
  Video = 'VIDEO'
}

/** Mutations. */
export type Mutation = {
  /** Clear basket. */
  clear?: Maybe<Scalars['String']['output']>;
  /** Basket copy endpoint. */
  copy: Scalars['String']['output'];
  /** Delete basket. */
  delete?: Maybe<Scalars['String']['output']>;
  /** Basket email endpoint. */
  email: Scalars['Boolean']['output'];
  /** Basket submit endpoint. */
  submit: Scalars['Boolean']['output'];
};


/** Mutations. */
export type MutationClearArgs = {
  basketId: Scalars['String']['input'];
};


/** Mutations. */
export type MutationCopyArgs = {
  accountId?: InputMaybe<Scalars['String']['input']>;
  basketId: Scalars['String']['input'];
  preserveOriginalBasket?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Mutations. */
export type MutationDeleteArgs = {
  basketId: Scalars['String']['input'];
};


/** Mutations. */
export type MutationEmailArgs = {
  basketId: Scalars['String']['input'];
  request: BasketEmailRequest;
};


/** Mutations. */
export type MutationSubmitArgs = {
  basketId: Scalars['String']['input'];
  timeZoneId: Scalars['String']['input'];
};

/** Never out of stock available types */
export enum NeverOutOfStockType {
  Basic = 'BASIC',
  Limited = 'LIMITED',
  Seasonal = 'SEASONAL'
}

/** Order data. */
export type Order = {
  /** Defines account info. */
  account?: Maybe<OrderAccountInfo>;
  /** Defines address. */
  address?: Maybe<Scalars['String']['output']>;
  /** Defines basket id. */
  basketId?: Maybe<Scalars['String']['output']>;
  /** Defines client reference number. */
  clientReferenceNumber?: Maybe<Scalars['String']['output']>;
  /** Defines quantity (confirmed). */
  confirmedQuantity?: Maybe<Scalars['Int']['output']>;
  /** Defines order date (placed). */
  orderDate?: Maybe<Scalars['DateTime']['output']>;
  /** Defines order reference number. */
  orderReferenceNumber?: Maybe<Scalars['String']['output']>;
  /** Defines order type. */
  orderType?: Maybe<Scalars['String']['output']>;
  /** Defines net price (confirmed). */
  priceNetConfirmed?: Maybe<OrderItemPrice>;
  /** Defines RRP price. */
  priceRrp?: Maybe<OrderItemPrice>;
  /** Defines date (requested). */
  requestedDate?: Maybe<Scalars['DateTime']['output']>;
  /** Defines quantity (requested). */
  requestedQuantity?: Maybe<Scalars['Int']['output']>;
  /** Defines root product brand id */
  rootBrandId?: Maybe<Scalars['String']['output']>;
  /** Defines root product id */
  rootId?: Maybe<Scalars['String']['output']>;
  /** Defines root product title */
  rootTitle?: Maybe<Scalars['String']['output']>;
  /** Defines sales order number. */
  salesOrderNumber: Scalars['String']['output'];
  /** Defines order date (shipping). */
  shippingDeliveryDate?: Maybe<Scalars['String']['output']>;
  /** Defines SalesOrderLineStatus. */
  status?: Maybe<Scalars['String']['output']>;
  /** Defines thumbnails of products that are part of this request. */
  thumbnails?: Maybe<Array<Scalars['String']['output']>>;
  /** Defines price (net). */
  totalPriceNet?: Maybe<OrderItemPrice>;
  /** Defines tracking numbers. */
  trackingNumbers?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** Defines unit net price. */
  unitPriceNet?: Maybe<OrderItemPrice>;
  /** Defines unit RRP price. */
  unitPriceRrp?: Maybe<OrderItemPrice>;
  /** Defines order date (updated). */
  updatedDate?: Maybe<Scalars['DateTime']['output']>;
};

/** Account info. */
export type OrderAccountInfo = {
  /** Defines account external id. */
  externalId?: Maybe<Scalars['String']['output']>;
  /** Defines account id. */
  id?: Maybe<Scalars['String']['output']>;
  /** Defines account name. */
  name?: Maybe<Scalars['String']['output']>;
};

/** Search filter options. */
export type OrderFilter = {
  /** Defines filtering option display name. */
  displayName: Scalars['String']['output'];
  /** Defines filtering field. */
  fieldId: Scalars['String']['output'];
  /** Defines if filter values are facets. */
  isFacet: Scalars['Boolean']['output'];
  /** Defines if multiple filter values selection is allowed. */
  isMultiple: Scalars['Boolean']['output'];
  /** Defines filter option order. */
  order: Scalars['Int']['output'];
  /** Defines filter type. */
  type: FilterType;
  /** Array of filter values. */
  values?: Maybe<Array<FilterValue>>;
};

/** Response object for order filters. */
export type OrderFilters = {
  /** Array of filters. */
  filters: Array<OrderFilter>;
  /** Total item count for current filter selection. */
  total: Scalars['Int']['output'];
};

/** Order item data. */
export type OrderItem = {
  /** Defines brand. */
  brandId?: Maybe<Scalars['String']['output']>;
  /** Defines color text. */
  colorText?: Maybe<Scalars['String']['output']>;
  /** Defines order date (confirmed). */
  confirmedDeliveryDate?: Maybe<Scalars['String']['output']>;
  /** Defines quantity (confirmed). */
  confirmedQuantity?: Maybe<Scalars['Int']['output']>;
  /** Defines quantity (delivered). */
  deliveredQuantity?: Maybe<Scalars['Int']['output']>;
  /** Defines delivery numbers. */
  deliveryNumbers?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** Defines discount unit value. */
  discount?: Maybe<OrderItemPrice>;
  /** Defines discount percentage. */
  discountPercentage?: Maybe<Scalars['Float']['output']>;
  /** Optional product id with removed prefix */
  displayedProductId?: Maybe<Scalars['String']['output']>;
  /** Defines id. */
  id: Scalars['String']['output'];
  /** Defines invoice numbers. */
  invoiceNumbers?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** Defines markup. */
  markup?: Maybe<Scalars['Float']['output']>;
  /** Defines gross price. */
  priceGross?: Maybe<OrderItemPrice>;
  /** Defines net price. */
  priceNet?: Maybe<OrderItemPrice>;
  /** Defines net price (confirmed). */
  priceNetConfirmed?: Maybe<OrderItemPrice>;
  /** Defines RRP price. */
  priceRrp?: Maybe<OrderItemPrice>;
  /** Defines order date (requested). */
  requestedDeliveryDate?: Maybe<Scalars['String']['output']>;
  /** Defines quantity (requested). */
  requestedQuantity?: Maybe<Scalars['Int']['output']>;
  /** Defines root id. */
  rootId?: Maybe<Scalars['String']['output']>;
  /** Defines root title. */
  rootTitle?: Maybe<Scalars['String']['output']>;
  /** Defines salesOrderNumber. */
  salesOrderNumber?: Maybe<Scalars['String']['output']>;
  /** Defines order date (shipping). */
  shippingDeliveryDate?: Maybe<Scalars['String']['output']>;
  /** Defines order date (submitted). */
  submittedDeliveryDate?: Maybe<Scalars['String']['output']>;
  /** Defines title. */
  title?: Maybe<Scalars['String']['output']>;
  /** Defines tracking numbers. */
  trackingNumbers?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** Defines quantity (unconfirmed). */
  unconfirmedQuantity?: Maybe<Scalars['Int']['output']>;
  /** Defines discount value. */
  unitDiscount?: Maybe<OrderItemPrice>;
  /** Defines unit gross price. */
  unitPriceGross?: Maybe<OrderItemPrice>;
  /** Defines unit net price. */
  unitPriceNet?: Maybe<OrderItemPrice>;
  /** Defines unit RRP price. */
  unitPriceRrp?: Maybe<OrderItemPrice>;
  /** Defines variants. */
  variants?: Maybe<Array<Maybe<VariantItem>>>;
};

/** Order item price. */
export type OrderItemPrice = {
  /** Defines currency value. */
  currency?: Maybe<Scalars['String']['output']>;
  /** Defines price value. */
  value?: Maybe<Scalars['Float']['output']>;
};

/** Represent Order types */
export enum OrderType {
  Future = 'FUTURE',
  Mixed = 'MIXED',
  None = 'NONE',
  Now = 'NOW'
}

export type OrderTypeBasketColumn = {
  hidden?: Maybe<Scalars['Boolean']['output']>;
  key: BasketColumnKeys;
  orderType?: Maybe<OrderType>;
  overwritten?: Maybe<Scalars['Boolean']['output']>;
  primaryValue?: Maybe<Scalars['String']['output']>;
};

/** Order paged response */
export type OrdersPartitionedResponse = {
  /** Has next page */
  hasNextPartition: Scalars['Boolean']['output'];
  /** Orders */
  orders: Array<Order>;
  /** Pagination information */
  pagination: PaginationInfo;
  /** Page number */
  partition: Scalars['Int']['output'];
  /** Totals */
  total?: Maybe<OrdersTotalResponse>;
};

export type OrdersTotalResponse = {
  confirmedQuantity?: Maybe<Scalars['Int']['output']>;
  requestedQuantity?: Maybe<Scalars['Int']['output']>;
  totalPriceNet?: Maybe<OrderItemPrice>;
};

/** Pagination query */
export type PaginationInfo = {
  afterKey?: Maybe<Scalars['String']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

/** Pagination query */
export type PaginationQuery = {
  afterKey?: InputMaybe<Scalars['String']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
};

/** Partition filter */
export type PartitionFilter = {
  numberOfPartitions?: InputMaybe<Scalars['Int']['input']>;
  partition: Scalars['Int']['input'];
};

/**
 * Pack of products configured by the brands to sell their products by packs instead of individually.
 * All the products configured in the quantities of a prepack are descendants of the same ancestor.
 */
export type Prepack = {
  /**
   * Key-value dictionary of dynamic product attributes.
   * A product can have multiple dynamic attribute with no direct business logic associated
   * but different business value depending on the industry, brand, customer, etc.
   * This dynamic attributes are commonly exported from ERP systems and may be requested to be shown in the application.
   */
  attributes: Scalars['ProductAttributes']['output'];
  /** Id of the brand */
  brandId: Scalars['String']['output'];
  /** Inventory attached to the product */
  inventoryItems?: Maybe<Array<InventoryItem>>;
  /** Prepack price ranges */
  priceRanges: Array<ProductPriceRanges>;
  /** Identifier of the prepack as a sellable product */
  productId: Scalars['String']['output'];
  /** Quantities per product contained in the prepack. */
  quantities: Array<PrepackUnit>;
  /** Title given to the product as sellable */
  title: Scalars['String']['output'];
};


/**
 * Pack of products configured by the brands to sell their products by packs instead of individually.
 * All the products configured in the quantities of a prepack are descendants of the same ancestor.
 */
export type PrepackInventoryItemsArgs = {
  orderType?: InputMaybe<Scalars['String']['input']>;
};

/** Defines how many units of a product are contained in a unit of a prepack. */
export type PrepackUnit = {
  /** Brand Id. Part of the unique key of the product */
  brandId: Scalars['String']['output'];
  /** Product Id. Part of the unique key of the product */
  productId: Scalars['String']['output'];
  /** How many units of the product art part of the PrepackUnit */
  quantity: Scalars['Int']['output'];
};

/** Defines how many units of a product are contained in a unit of a prepack. */
export type PrepackUnitItem = {
  /** Brand Id. Part of the unique key of the product */
  brandId: Scalars['String']['output'];
  /** Product Id. Part of the unique key of the product */
  productId: Scalars['String']['output'];
  /** How many units of the product art part of the PrepackUnit */
  quantity: Scalars['Int']['output'];
};

export type PreviewItem = {
  confirmedQuantity?: Maybe<Scalars['Int']['output']>;
  itemId: Scalars['String']['output'];
  requestedQuantity: Scalars['Int']['output'];
};

export type PriceBasketColumn = {
  actual?: Maybe<Scalars['Float']['output']>;
  currency: Scalars['String']['output'];
  hidden?: Maybe<Scalars['Boolean']['output']>;
  key: BasketColumnKeys;
  overwritten?: Maybe<Scalars['Boolean']['output']>;
  primaryValue?: Maybe<Scalars['String']['output']>;
  secondaryValue?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

/** Prices for a product */
export type PriceData = {
  /** Brand Id for the product which prices relate to */
  brandId: Scalars['String']['output'];
  /** List of prices */
  prices: Array<ProductPriceRanges>;
  /** Product Id for the prices which prices relate to */
  productId: Scalars['String']['output'];
};

/**
 * Abstract product type.
 * It can be `Article`, `Variant` or `Size`.
 */
export type Product = {
  /**
   * `Article` to which current product belongs.
   * Corresponds to the master product.
   * Empty when the product is an article itself
   */
  article?: Maybe<Product>;
  /** Default article title of the product. */
  articleImageUrl: Scalars['String']['output'];
  /** Default article title of the product. */
  articleTitle: Scalars['String']['output'];
  /**
   * Key-value dictionary of dynamic product attributes.
   * A product can have multiple dynamic attribute with no direct business logic associated
   * but different business value depending on the industry, brand, customer, etc.
   * This dynamic attributes are commonly exported from ERP systems and may be requested to be shown in the application.
   */
  attributes: Scalars['ProductAttributes']['output'];
  /** Product availability months according to availability blocks */
  availabilityMonths?: Maybe<Array<Scalars['String']['output']>>;
  /** Unique Brand ID in ERP systems. */
  brandId: Scalars['String']['output'];
  /** Defines if product is marketable. (Can be added to lists, searched) */
  canBeMarketed?: Maybe<Scalars['Boolean']['output']>;
  /** Defines if product is sellable. (Sizes, pre-packs) */
  canBeSold?: Maybe<Scalars['Boolean']['output']>;
  /** A product that is only marketable will have at least one child. */
  children?: Maybe<Array<Product>>;
  /** Color attribute */
  color?: Maybe<Scalars['String']['output']>;
  /** Color code attribute */
  colorCode?: Maybe<Scalars['String']['output']>;
  /** Brand org defines if delivery of the order can be scheduled for an exact day, or week, or month */
  deliveryWindowType: DeliveryWindowType;
  /** Description given to the product */
  description?: Maybe<Scalars['String']['output']>;
  /** Array of attributes that are representative descriptions of the product */
  descriptionAttributes?: Maybe<Array<DescriptionAttribute>>;
  /** Unique Product displayed ID. */
  displayedProductId?: Maybe<Scalars['String']['output']>;
  /** Earliest available date for the delivery */
  earliestDeliveryDate?: Maybe<Scalars['String']['output']>;
  /** Product icons. */
  icons?: Maybe<ProductIcons>;
  /** Inventory attached to the product */
  inventoryItems?: Maybe<Array<InventoryItem>>;
  /** Check is product is available */
  isAvailable?: Maybe<Scalars['Boolean']['output']>;
  /**
   * Is product blocked.
   * `false` if not defined.
   */
  isBlocked?: Maybe<Scalars['Boolean']['output']>;
  /** Is the product locked. This currently controls the lock on the product/variant level. */
  isLocked?: Maybe<Scalars['Boolean']['output']>;
  /** When this flag is true the product is a recent entry. */
  isNew?: Maybe<Scalars['Boolean']['output']>;
  /** `true` when there are business rules preventing the user from buying the product. */
  isUserRestricted?: Maybe<Scalars['Boolean']['output']>;
  /** Product icons configuration. */
  labels?: Maybe<Array<ProductLabel>>;
  /**
   * Url to main image.
   * Duplicated with `media` to simplify access.
   */
  mainImageUrl?: Maybe<Scalars['String']['output']>;
  /** Key-value dictionary of dynamic product attributes master values. */
  masterAttributes: Scalars['ProductAttributes']['output'];
  /** Media associated with the product. */
  media: Array<ProductMedia>;
  /** NeverOutOfStock type of the product. */
  neverOutOfStockType?: Maybe<NeverOutOfStockType>;
  /**
   * Parent product.
   * Empty when it's an article
   */
  parent?: Maybe<Product>;
  /** Parent id */
  parentId?: Maybe<Scalars['String']['output']>;
  /** Product popularity attribute, represents product popularity calculation */
  popularity?: Maybe<Scalars['Float']['output']>;
  /** Pre configured packs of products meant to be sold as a whole, not separately. */
  prepacks?: Maybe<Array<Prepack>>;
  /** Product price ranges */
  priceRanges: Array<ProductPriceRanges>;
  /** ProductPrices */
  prices?: Maybe<ProductPrices>;
  /** Unique Product ID in ERP systems. */
  productId: Scalars['String']['output'];
  /** Type of the product. */
  productType: ProductType;
  /** Related variants, include related variants for Surface The Variants flow (used to display related variants in p-card) */
  relatedVariants?: Maybe<Array<Product>>;
  /**
   * Scales defined for the product. This can contain multiple coded scales.
   * For instance: sizes for UK and sizes for US.
   */
  scaleSets?: Maybe<ScaleSets>;
  /** Default title of the product. */
  title: Scalars['String']['output'];
};


/**
 * Abstract product type.
 * It can be `Article`, `Variant` or `Size`.
 */
export type ProductInventoryItemsArgs = {
  orderType?: InputMaybe<Scalars['String']['input']>;
};

export type ProductByReferences = {
  products: Array<Maybe<Product>>;
  totalResultCount: Scalars['Int']['output'];
};

export type ProductByReferencesQuery = {
  accountId: Scalars['String']['input'];
  availability?: InputMaybe<Scalars['String']['input']>;
  available?: InputMaybe<Scalars['Boolean']['input']>;
  collapse?: InputMaybe<Scalars['Boolean']['input']>;
  f?: InputMaybe<Scalars['String']['input']>;
  includeFilters?: InputMaybe<Scalars['Boolean']['input']>;
  includeLocalizedValues?: InputMaybe<Scalars['Boolean']['input']>;
  includePopularity?: InputMaybe<Scalars['Boolean']['input']>;
  maxAge?: InputMaybe<Scalars['Int']['input']>;
  num?: InputMaybe<Scalars['Int']['input']>;
  preset?: InputMaybe<Scalars['String']['input']>;
  productListId?: InputMaybe<Scalars['String']['input']>;
  productReferences?: InputMaybe<Array<InputMaybe<ProductReferenceInput>>>;
  q?: InputMaybe<Scalars['String']['input']>;
  rrp?: InputMaybe<Scalars['String']['input']>;
  showNewDays?: InputMaybe<Scalars['Boolean']['input']>;
  site: Scalars['String']['input'];
  sortDirection?: InputMaybe<Scalars['Int']['input']>;
  sortField?: InputMaybe<Scalars['Int']['input']>;
  start?: InputMaybe<Scalars['Int']['input']>;
  subsites?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  topHitsGroup?: InputMaybe<Scalars['String']['input']>;
  topHitsNum?: InputMaybe<Scalars['Int']['input']>;
  topHitsSubgroups?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  type?: InputMaybe<Scalars['String']['input']>;
  wspdp?: InputMaybe<Scalars['Boolean']['input']>;
  wspdpr?: InputMaybe<Scalars['String']['input']>;
};

/** Product Icons, determines product icons */
export type ProductIcons = {
  /** Flag indicates global noos icon visibility */
  hasGlobalNoosIcon: Scalars['Boolean']['output'];
  /** Flag indicates marketing support icon visibility */
  hasMarketingSupportIcon: Scalars['Boolean']['output'];
  /** Flag indicates new icon visibility */
  hasNewIconByDeliveryMonth?: Maybe<Scalars['Boolean']['output']>;
  /** Flag indicates popularity icon visibility */
  hasPopularityIcon: Scalars['Boolean']['output'];
  /** Flag indicates sustainable choice icon visibility */
  hasSustainableChoiceIcon: Scalars['Boolean']['output'];
  /** Marketing support end of life date time */
  marketingSupportEolDate?: Maybe<Scalars['DateTime']['output']>;
  /** Returns product popularity from search query */
  popularity?: Maybe<Scalars['Float']['output']>;
  /** Array of supported product sustainable choice descriptions */
  sustainableChoiceDescriptions?: Maybe<Array<Scalars['String']['output']>>;
  /** Array of supported product sustainable choices */
  sustainableChoiceNames?: Maybe<Array<Scalars['String']['output']>>;
};

export type ProductKey = {
  brandId: Scalars['String']['input'];
  productId: Scalars['String']['input'];
};

/** Product Labels, determines product icons */
export type ProductLabel = {
  /** Product label data */
  data?: Maybe<Scalars['JSON']['output']>;
  /** Product label type */
  labelType: ProductLabelType;
  /** Product label name */
  name: Scalars['String']['output'];
};

export enum ProductLabelType {
  Badge = 'Badge',
  Frame = 'Frame',
  Icon = 'Icon'
}

/** Media information associated with a product. */
export type ProductMedia = {
  /** Type of the media (image, video, thumbnail, 360). */
  type: MediaType;
  /** Url to the media. */
  url: Scalars['String']['output'];
  /** Urls to the media for 360 Images */
  urls?: Maybe<Array<Scalars['String']['output']>>;
};

export type ProductPrice = {
  currency?: Maybe<Scalars['String']['output']>;
  priceMax?: Maybe<Scalars['Float']['output']>;
  priceMin?: Maybe<Scalars['Float']['output']>;
  validityPeriod?: Maybe<DateRange>;
};

/** Represents all the price ranges for a given product */
export type ProductPriceRanges = {
  currency?: Maybe<Scalars['String']['output']>;
  discount?: Maybe<FloatRange>;
  markup?: Maybe<Scalars['Float']['output']>;
  rrp?: Maybe<FloatRange>;
  validityPeriod?: Maybe<DateRange>;
  wsp?: Maybe<FloatRange>;
  wspd?: Maybe<FloatRange>;
};

export type ProductPrices = {
  discount?: Maybe<Array<Maybe<ProductPrice>>>;
  future?: Maybe<GenericProductPrice>;
  now?: Maybe<GenericProductPrice>;
  rrp?: Maybe<Array<Maybe<ProductPrice>>>;
  wsp?: Maybe<Array<Maybe<ProductPrice>>>;
  wspd?: Maybe<Array<Maybe<ProductPrice>>>;
};

export type ProductReferenceInput = {
  brandId: Scalars['String']['input'];
  productId: Scalars['String']['input'];
};

/** Product types. */
export enum ProductType {
  Article = 'ARTICLE',
  Material = 'MATERIAL',
  Size = 'SIZE'
}

export type QuantityBasketColumn = {
  actual?: Maybe<Scalars['Float']['output']>;
  hidden?: Maybe<Scalars['Boolean']['output']>;
  key: BasketColumnKeys;
  overwritten?: Maybe<Scalars['Boolean']['output']>;
  primaryValue?: Maybe<Scalars['String']['output']>;
  secondaryValue?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

/** Query type. */
export type Query = {
  account: Account;
  accountCountries: Array<AccountCountry>;
  accountReviewers: Array<AccountReviewer>;
  accountTypes?: Maybe<AccountTypesPagedResponse>;
  accounts?: Maybe<AccountsPagedResponse>;
  accountsSelector?: Maybe<AccountsSelectorPagedResponse>;
  /** Returns basket data. */
  basket?: Maybe<Basket>;
  basketAccounts: Array<Account>;
  brandOrgs?: Maybe<BrandOrgsPagedResponse>;
  dab?: Maybe<Array<DabData>>;
  dabHistory?: Maybe<Array<DabHistory>>;
  exportSearch: Scalars['Boolean']['output'];
  /**
   * Obsolete.
   * Returns configurable filters for orderbook
   */
  filter?: Maybe<Array<OrderFilter>>;
  /** Returns account filter for orderbook */
  filterAccounts?: Maybe<Array<OrderFilter>>;
  /** Returns year root filter for orderbook */
  filterYear?: Maybe<Array<OrderFilter>>;
  /** Returns configurable filters for orderbook */
  filters: OrderFilters;
  findSlimProduct?: Maybe<Product>;
  /** Returns available filter values base on data */
  getBasketManagerFilters?: Maybe<BasketManagerFilterValues>;
  /** Returns filtered data for basket manager */
  getBasketManagerList?: Maybe<BasketManagerResult>;
  /** Returns grouped baskets data. */
  groupedBaskets?: Maybe<GroupedBasketsResult>;
  /** Returns order by order number for orderbook */
  order?: Maybe<Array<Maybe<OrderItem>>>;
  /** Returns orders by configurable filters for orderbook */
  orders: OrdersPartitionedResponse;
  prices: Array<Maybe<PriceData>>;
  product?: Maybe<Product>;
  productByBarcode?: Maybe<Product>;
  productsByBarcodes: Array<Maybe<BarcodesSearch>>;
  productsByReferences?: Maybe<ProductByReferences>;
  search?: Maybe<SearchResult>;
  /** Returns lightweight info for baskets */
  searchBaskets?: Maybe<BasketSearchResult>;
  searchOptions?: Maybe<SearchOptions>;
  searchSuggest?: Maybe<SearchSuggestResult>;
  /** Returns lightweight info for users baskets */
  searchUserBaskets?: Maybe<UserBasketsSearchResult>;
  users?: Maybe<UsersPagedResponse>;
};


/** Query type. */
export type QueryAccountArgs = {
  accountId: Scalars['String']['input'];
};


/** Query type. */
export type QueryAccountCountriesArgs = {
  accountId: Scalars['String']['input'];
};


/** Query type. */
export type QueryAccountReviewersArgs = {
  accountId: Scalars['String']['input'];
};


/** Query type. */
export type QueryAccountTypesArgs = {
  query?: InputMaybe<AccountTypesPageQuery>;
};


/** Query type. */
export type QueryAccountsArgs = {
  query?: InputMaybe<AccountsQuery>;
};


/** Query type. */
export type QueryAccountsSelectorArgs = {
  query?: InputMaybe<AccountsSelectorPageQuery>;
};


/** Query type. */
export type QueryBasketArgs = {
  basketId: Scalars['String']['input'];
  groupBy: BasketRowsGroupType;
  sortBy?: InputMaybe<BasketGroupSortType>;
  sortDirection?: InputMaybe<SortDirection>;
};


/** Query type. */
export type QueryBasketAccountsArgs = {
  basketId: Scalars['String']['input'];
};


/** Query type. */
export type QueryBrandOrgsArgs = {
  query?: InputMaybe<BrandOrgsPageQuery>;
};


/** Query type. */
export type QueryDabArgs = {
  query?: InputMaybe<DabQuery>;
};


/** Query type. */
export type QueryDabHistoryArgs = {
  query?: InputMaybe<DabQuery>;
};


/** Query type. */
export type QueryExportSearchArgs = {
  query?: InputMaybe<SearchQuery>;
};


/** Query type. */
export type QueryFilterArgs = {
  query?: InputMaybe<FilterQuery>;
};


/** Query type. */
export type QueryFilterAccountsArgs = {
  query?: InputMaybe<FilterQuery>;
};


/** Query type. */
export type QueryFilterYearArgs = {
  count?: InputMaybe<Scalars['Int']['input']>;
};


/** Query type. */
export type QueryFiltersArgs = {
  query?: InputMaybe<FilterQuery>;
};


/** Query type. */
export type QueryFindSlimProductArgs = {
  brandId: Scalars['String']['input'];
  includeRelatedProducts?: InputMaybe<Scalars['Boolean']['input']>;
  productId: Scalars['String']['input'];
};


/** Query type. */
export type QueryGetBasketManagerFiltersArgs = {
  query?: InputMaybe<BasketManagerFilterQuery>;
};


/** Query type. */
export type QueryGetBasketManagerListArgs = {
  query?: InputMaybe<BasketManagerListRequest>;
};


/** Query type. */
export type QueryGroupedBasketsArgs = {
  basketId: Scalars['String']['input'];
  groupsBy: Array<BasketRowsGroupType>;
  settings: BasketMappingConfig;
};


/** Query type. */
export type QueryOrderArgs = {
  accountIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  orderNumber?: InputMaybe<Scalars['String']['input']>;
};


/** Query type. */
export type QueryOrdersArgs = {
  query?: InputMaybe<FilterQuery>;
};


/** Query type. */
export type QueryPricesArgs = {
  products: Array<ProductKey>;
  type?: InputMaybe<Scalars['String']['input']>;
};


/** Query type. */
export type QueryProductArgs = {
  brandId: Scalars['String']['input'];
  includeLocalizedAttributes?: InputMaybe<Scalars['Boolean']['input']>;
  loadPrices?: InputMaybe<Scalars['Boolean']['input']>;
  loadTrueDemand?: InputMaybe<Scalars['Boolean']['input']>;
  productId: Scalars['String']['input'];
};


/** Query type. */
export type QueryProductByBarcodeArgs = {
  productBarcode: Scalars['String']['input'];
};


/** Query type. */
export type QueryProductsByBarcodesArgs = {
  barcodes: Array<InputMaybe<Scalars['String']['input']>>;
};


/** Query type. */
export type QueryProductsByReferencesArgs = {
  query: ProductByReferencesQuery;
};


/** Query type. */
export type QuerySearchArgs = {
  query?: InputMaybe<SearchQuery>;
};


/** Query type. */
export type QuerySearchBasketsArgs = {
  num?: InputMaybe<Scalars['Int']['input']>;
  orderReferenceNumber?: InputMaybe<Scalars['String']['input']>;
  sortBy?: InputMaybe<BasketSearchSortType>;
  sortDirection?: InputMaybe<BasketSearchSortDirection>;
  start?: InputMaybe<Scalars['Int']['input']>;
  statuses?: InputMaybe<Array<Scalars['Int']['input']>>;
};


/** Query type. */
export type QuerySearchOptionsArgs = {
  query?: InputMaybe<SearchFilterQuery>;
};


/** Query type. */
export type QuerySearchSuggestArgs = {
  query?: InputMaybe<SearchQuery>;
};


/** Query type. */
export type QuerySearchUserBasketsArgs = {
  basketsAmount?: InputMaybe<Scalars['Int']['input']>;
  num?: InputMaybe<Scalars['Int']['input']>;
  orderReferenceNumber?: InputMaybe<Scalars['String']['input']>;
  start?: InputMaybe<Scalars['Int']['input']>;
};


/** Query type. */
export type QueryUsersArgs = {
  query?: InputMaybe<UsersPageQuery>;
};

/** Filter range type. */
export type RangeFilterValue = {
  /** Filter value count, shows facet hit count. */
  count?: Maybe<Scalars['Int']['output']>;
  /** Defines filter value display name. */
  displayName: Scalars['String']['output'];
  /** Array of range values. */
  range?: Maybe<Array<RangeItem>>;
  /** Defines filter type. */
  type: FilterType;
  /** Defines filter value. */
  value: Scalars['String']['output'];
};

/** Filter range value type. */
export type RangeItem = {
  /** Defines filter value display name. */
  displayName: Scalars['String']['output'];
  /** Defines filter value. */
  value: Scalars['String']['output'];
};

export enum ReviewStatus {
  Accepted = 'Accepted',
  None = 'None',
  Pending = 'Pending',
  Rejected = 'Rejected'
}

/** Scale sets define different size scales, it can be used to define sizes for US / RU , EU regions or other custom scales. */
export type ScaleSets = {
  /** The preferred scale set to use. */
  default: Scalars['String']['output'];
  /** Lisf of all the configured scale sets for a product */
  scales: Scalars['ScaleSet']['output'];
};

/** Search filter query type. */
export type SearchFilterQuery = {
  /** Search query used for current search results */
  currentSearchQuery: SearchQuery;
  /** Only used for optimization purposes so we filter out unneeded filters calls to API */
  lastSelected?: InputMaybe<Scalars['String']['input']>;
  /** Only used for optimization purposes so we filter out unneeded filters calls to API */
  previousSearchQuery?: InputMaybe<SearchQuery>;
  /** Use V2 Search */
  useV2Search?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Search options. */
export type SearchOptions = {
  /** Array of filtering options available for search. */
  filters?: Maybe<Array<Filter>>;
  /** Array of sorting options available for search. */
  sorting?: Maybe<Array<Sorting>>;
  /** Number of products return by current filters */
  totalResultCount: Scalars['Int']['output'];
};

/** Search filter query type. */
export type SearchQuery = {
  /** Search availability setting */
  availability?: InputMaybe<Scalars['String']['input']>;
  /** Product availability months according to availability blocks */
  availabilityMonths?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Search for available, unavailable products for the account */
  available?: InputMaybe<Scalars['Boolean']['input']>;
  /** Enable or disable surface the variant */
  collapse?: InputMaybe<Scalars['Boolean']['input']>;
  /**
   * Search facet value in example
   * {
   *   f={facetId}:{facetValue1}|{facetValue2},{facetId}:{facetValue}
   * }
   */
  f?: InputMaybe<Scalars['String']['input']>;
  filterOutInbound?: InputMaybe<Scalars['String']['input']>;
  filterOutRecentlyDelivered?: InputMaybe<Scalars['String']['input']>;
  inventoryRange?: InputMaybe<Scalars['String']['input']>;
  /** Specifies isdDates that should be added to a query */
  isdDates?: InputMaybe<Scalars['String']['input']>;
  /** Search max age setting */
  maxAge?: InputMaybe<Scalars['String']['input']>;
  /** Search query products per page */
  num?: InputMaybe<Scalars['Int']['input']>;
  orderHistoryFromDate?: InputMaybe<Scalars['String']['input']>;
  orderHistoryToDate?: InputMaybe<Scalars['String']['input']>;
  orderedProductsBrandId?: InputMaybe<Scalars['String']['input']>;
  /** Specifies preset that should be added to a query */
  preset?: InputMaybe<Scalars['String']['input']>;
  /** Search filtered by product list */
  productListId?: InputMaybe<Scalars['String']['input']>;
  /** Search query */
  q?: InputMaybe<Scalars['String']['input']>;
  /** Refresh Contxt */
  refreshContext?: InputMaybe<Scalars['Boolean']['input']>;
  /** Search rrp setting */
  rrp?: InputMaybe<Scalars['String']['input']>;
  /**
   * Search site
   *   @deprecated(
   *     reason: "Site is retrieved from header epc-site-id"
   *   )
   */
  site?: InputMaybe<Scalars['String']['input']>;
  /** Search sort direction setting */
  sortDirection?: InputMaybe<SortingDirection>;
  /** Search sort field setting */
  sortField?: InputMaybe<SearchSortType>;
  /** Search query page offset */
  start?: InputMaybe<Scalars['Int']['input']>;
  /** Search sub sites filter */
  subsites?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Search top hits group */
  topHitsGroup?: InputMaybe<Scalars['String']['input']>;
  /** Search top hits number */
  topHitsNum?: InputMaybe<Scalars['Int']['input']>;
  /** Search top hits sub groups */
  topHitsSubgroups?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Order type */
  type?: InputMaybe<Scalars['String']['input']>;
  /** Use Catalog Only Mode */
  useCatalogOnlyMode?: InputMaybe<Scalars['Boolean']['input']>;
  /** Use V2 Search */
  useV2Search?: InputMaybe<Scalars['Boolean']['input']>;
  /** Should it return related variants or not */
  withRelatedVariants?: InputMaybe<Scalars['Boolean']['input']>;
  /** Search wsp setting */
  wsp?: InputMaybe<Scalars['String']['input']>;
  /** Search wspdp flag */
  wspdp?: InputMaybe<Scalars['Boolean']['input']>;
  /** Search wspdpr setting */
  wspdpr?: InputMaybe<Scalars['String']['input']>;
};

/** Represents a search model. */
export type SearchResult = {
  /** Products associated with the current search request. */
  products?: Maybe<Array<Product>>;
  /** Total result count */
  totalResultCount: Scalars['Int']['output'];
};

/** Search sort options */
export enum SearchSortType {
  ArticleNumberAttribute = 'ArticleNumberAttribute',
  ArticleTitle = 'ArticleTitle',
  Availability = 'Availability',
  BoughtThisSeason = 'BoughtThisSeason',
  Category = 'Category',
  Default = 'Default',
  DiscountPercentage = 'DiscountPercentage',
  Inbound = 'Inbound',
  IsdDate = 'IsdDate',
  Markup = 'Markup',
  MostPopularVariants = 'MostPopularVariants',
  New = 'New',
  Rrp = 'Rrp',
  Stock = 'Stock',
  Wsp = 'Wsp'
}

/** Represents a search suggest model. */
export type SearchSuggestResult = {
  /** Products associated with the current search request. */
  products?: Maybe<Array<SuggestProduct>>;
  /** Suggestions associated with the current search request. */
  suggestions?: Maybe<Array<SearchSuggestion>>;
  /** Total result count */
  totalResultCount: Scalars['Int']['output'];
};

export type SearchSuggestion = {
  field: Scalars['String']['output'];
  /** Filter field id */
  filterField?: Maybe<Scalars['String']['output']>;
  isFilter: Scalars['Boolean']['output'];
  options?: Maybe<Array<SearchSuggestionOption>>;
  translationKey?: Maybe<Scalars['String']['output']>;
};

export type SearchSuggestionOption = {
  isActive?: Maybe<Scalars['Boolean']['output']>;
  text?: Maybe<Scalars['String']['output']>;
};

/**
 * Validation message severity.
 * CRITICAL and ERROR do not allow to continue an order.
 * Other levels are informative.
 */
export enum Severity {
  Critical = 'CRITICAL',
  Debug = 'DEBUG',
  Error = 'ERROR',
  Information = 'INFORMATION',
  Success = 'SUCCESS',
  Warning = 'WARNING'
}

/** Generic group for basket items. */
export type ShippingOption = {
  /** Code of the shipping. */
  code?: Maybe<Scalars['String']['output']>;
  /** Id of the shipping. */
  id: Scalars['String']['output'];
  /** Used flag of the shipping. */
  isUsed?: Maybe<Scalars['Boolean']['output']>;
  /** Name of the shipping. */
  name?: Maybe<Scalars['String']['output']>;
};

export type SimpleFilterValue = {
  count?: Maybe<Scalars['Int']['output']>;
  imageUrl?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

/** Account site. */
export type Site = {
  /** Brand id */
  brandId?: Maybe<Scalars['String']['output']>;
  /** Is site market site */
  isMarketSite?: Maybe<Scalars['Boolean']['output']>;
  /** Site logo url */
  logoUrl?: Maybe<Scalars['String']['output']>;
  /** Site display order */
  order?: Maybe<Scalars['Int']['output']>;
  /** Site id */
  siteId: Scalars['String']['output'];
  /** Is subsites required */
  subSiteRequired?: Maybe<Scalars['Boolean']['output']>;
  /** Site subsites */
  subSites?: Maybe<Array<Maybe<SubSite>>>;
  /** Site title */
  title?: Maybe<Scalars['String']['output']>;
};

export enum SortDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

/** Search sorting options. */
export type Sorting = {
  /** Defines sorting direction for specified field. */
  direction: SortingDirection;
  /** Defines sorting option display name. */
  displayName: Scalars['String']['output'];
  /** Defines sorting field. */
  fieldId: Scalars['String']['output'];
  /** Defines the key used for translation. */
  translationKey?: Maybe<Scalars['String']['output']>;
};

/** Sorting direction. */
export enum SortingDirection {
  Asc = 'Asc',
  Desc = 'Desc'
}

/** Site subsite */
export type SubSite = {
  /** SubSite custom url */
  customUrl?: Maybe<Scalars['String']['output']>;
  /** Is SubSite visible in brand selector */
  isBrandSelectorVisible?: Maybe<Scalars['Boolean']['output']>;
  /** Is SubSite a filter */
  isFilter?: Maybe<Scalars['Boolean']['output']>;
  /** logoUrl */
  logoUrl?: Maybe<Scalars['String']['output']>;
  /** SubSite id */
  subSiteId: Scalars['String']['output'];
  /** SubSite title */
  title?: Maybe<Scalars['String']['output']>;
};

/** Abstract suggest product type. */
export type SuggestProduct = {
  /** Default article title of the product. */
  articleTitle: Scalars['String']['output'];
  /** Key-value dictionary of dynamic product attributes. */
  attributes: Scalars['ProductAttributes']['output'];
  /** Unique Brand ID in ERP systems. */
  brandId: Scalars['String']['output'];
  /** Color code attribute */
  colorCode?: Maybe<Scalars['String']['output']>;
  /** Unique Product displayed ID. */
  displayedProductId?: Maybe<Scalars['String']['output']>;
  /**
   * Url to main image.
   * Duplicated with `media` to simplify access.
   */
  mainImageUrl?: Maybe<Scalars['String']['output']>;
  /** Media associated with the product. */
  media: Array<ProductMedia>;
  /** Parent id */
  parentId: Scalars['String']['output'];
  /** Product price ranges */
  priceRanges: Array<ProductPriceRanges>;
  /** Unique Product ID in ERP systems. */
  productId: Scalars['String']['output'];
  /** Default title of the product. */
  title: Scalars['String']['output'];
};

/** Basket Summary */
export type Summary = {
  /** Number Of Items in the Basket */
  numberOfItems?: Maybe<Scalars['Int']['output']>;
  /** Total Basket product pieces count */
  totalPiecesQuantity: Scalars['Int']['output'];
  /** Total Basket value per currency */
  totalValueByCurrency?: Maybe<Scalars['ValueByCurrency']['output']>;
};

/** Summarized data. */
export type Total = {
  /** Aggrgated colors data. */
  colors?: Maybe<Array<TotalColorItem>>;
  /** Totals for confirmed basket items */
  confirmed?: Maybe<TotalItem>;
  /** Aggrgated discounts. */
  discounts?: Maybe<TotalDiscounts>;
  /** Manual discount */
  manualDiscount?: Maybe<Scalars['Float']['output']>;
  /** Aggregated products data. */
  products?: Maybe<Array<TotalProductItem>>;
  /** Product quantity ratio. */
  quantityRatio?: Maybe<Scalars['Float']['output']>;
  /** Totals for requested basket items. */
  requested: TotalItem;
  /** Totals for confirmed basket items */
  simulated?: Maybe<TotalItem>;
  /** Product WSP ratio. */
  wspRatio?: Maybe<Scalars['Float']['output']>;
};

export type TotalColorItem = {
  /** Hex representation of product color. */
  hex: Scalars['String']['output'];
  /** Number of quantities per color. */
  quantity: Scalars['Int']['output'];
};

export type TotalDiscounts = {
  /** Discount by retail price */
  rrp?: Maybe<Scalars['Float']['output']>;
  /** Discount by gross price */
  wsp?: Maybe<Scalars['Float']['output']>;
};

/** Total item. */
export type TotalItem = {
  /** Currency. */
  currency: Scalars['String']['output'];
  /** Rrp Currency. */
  currencyRrp: Scalars['String']['output'];
  /**
   * Total WSP Displayed.
   * wspd ?? wsp
   */
  displayedwsp: Scalars['Float']['output'];
  /** Total NET Price, is a WSP price confirmed by ERP. */
  net: Scalars['Float']['output'];
  /**
   * Total requested quantity. This originates from RequestedQuantity or ConfirmedQuantity.
   * The ConfirmedQuantity can be undefined.
   */
  quantity?: Maybe<Scalars['Int']['output']>;
  /** Total RRP. */
  rrp: Scalars['Float']['output'];
  /** Total WSP. */
  wsp: Scalars['Float']['output'];
  /** Total WSPD. */
  wspd: Scalars['Float']['output'];
};

/** Aggregated product item data. */
export type TotalProductItem = ITotalProductItem & {
  /** Basket item id. */
  basketItemIds: Array<Scalars['String']['output']>;
  /** Brand id. */
  brandId: Scalars['String']['output'];
  /** Image of material. */
  imageUrl: Scalars['String']['output'];
  /** Close out from product */
  isCloseOutProduct?: Maybe<Scalars['Boolean']['output']>;
  /** Product Id. */
  productId: Scalars['String']['output'];
  /** Quantity of basket items of material. */
  quantity: Scalars['Int']['output'];
  /** Title of the product. */
  title: Scalars['String']['output'];
};

/** User model */
export type User = {
  /** User display name */
  displayName?: Maybe<Scalars['String']['output']>;
  /** User email */
  emailAddress?: Maybe<Scalars['String']['output']>;
  /** User Id */
  id: Scalars['Int']['output'];
  /** User roles */
  roles?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** user name */
  userName?: Maybe<Scalars['String']['output']>;
};

/** User with newest created Baskets */
export type UserBaskets = {
  baskets?: Maybe<Array<Maybe<BasketSlim>>>;
  user?: Maybe<UserExtended>;
};

/** Paginated result for lightweight basket model */
export type UserBasketsSearchResult = {
  items?: Maybe<Array<Maybe<UserBaskets>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

/** Basket User */
export type UserExtended = {
  displayName?: Maybe<Scalars['String']['output']>;
  emailAddress?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  profilePictureUrl?: Maybe<Scalars['String']['output']>;
  userName?: Maybe<Scalars['String']['output']>;
};

/** Paginated and filtering query for users. */
export type UsersPageQuery = {
  /** Search by account id */
  accountIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Search by account type id */
  accountTypeIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Search by BrandOrg id */
  brandOrgIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Page number. */
  page?: InputMaybe<Scalars['Int']['input']>;
  /** Search by email and displayName */
  search?: InputMaybe<Scalars['String']['input']>;
  /** Amount of users returned per page. */
  size?: InputMaybe<Scalars['Int']['input']>;
};

/** Users paged response. */
export type UsersPagedResponse = {
  /** List of accountUsers. */
  items?: Maybe<Array<Maybe<AccountUser>>>;
  /** Total number of users. */
  total: Scalars['Int']['output'];
};

/** Generic validation result. */
export type ValidationResult = {
  /** Validation message. */
  message?: Maybe<Scalars['String']['output']>;
  /**
   * Validation message severity.
   * CRITICAL and ERROR do not allow to continue an order.
   * Other levels are informative.
   */
  severity: Severity;
  /** Validation message. */
  translationKey?: Maybe<Scalars['String']['output']>;
  /** Validation result values. */
  values?: Maybe<Array<Scalars['String']['output']>>;
};

/** Variant item. */
export type VariantItem = {
  /** Defines order date (confirmed). */
  confirmedDeliveryDate?: Maybe<Scalars['String']['output']>;
  /** Defines quantity (confirmed). */
  confirmedQuantity?: Maybe<Scalars['Int']['output']>;
  /** Defines quantity (delivered). */
  deliveredQuantity?: Maybe<Scalars['Int']['output']>;
  /** Defines discount unit value. */
  discount?: Maybe<OrderItemPrice>;
  /** Defines discount percentage. */
  discountPercentage?: Maybe<Scalars['Float']['output']>;
  /** Defines markup. */
  markup?: Maybe<Scalars['Float']['output']>;
  /** Defines gross price. */
  priceGross?: Maybe<OrderItemPrice>;
  /** Defines net price. */
  priceNet?: Maybe<OrderItemPrice>;
  /** Defines RRP price. */
  priceRrp?: Maybe<OrderItemPrice>;
  /** Defines order date (requested). */
  requestedDeliveryDate?: Maybe<Scalars['String']['output']>;
  /** Defines quantity (requested). */
  requestedQuantity?: Maybe<Scalars['Int']['output']>;
  /** Defines root id. */
  rootId?: Maybe<Scalars['String']['output']>;
  /** Defines order date (shipping). */
  shippingDeliveryDate?: Maybe<Scalars['String']['output']>;
  /** Defines variants. */
  sizes?: Maybe<Array<Maybe<VariantSize>>>;
  /** Defines order date (submitted). */
  submittedDeliveryDate?: Maybe<Scalars['String']['output']>;
  /** Defines SalesOrderLineStatus. */
  type?: Maybe<Scalars['String']['output']>;
  /** Defines quantity (unconfirmed). */
  unconfirmedQuantity?: Maybe<Scalars['Int']['output']>;
  /** Defines discount value. */
  unitDiscount?: Maybe<OrderItemPrice>;
  /** Defines unit gross price. */
  unitPriceGross?: Maybe<OrderItemPrice>;
  /** Defines unit net price. */
  unitPriceNet?: Maybe<OrderItemPrice>;
  /** Defines unit RRP price. */
  unitPriceRrp?: Maybe<OrderItemPrice>;
};

/** Variant Size. */
export type VariantSize = {
  /** Defines quantity (confirmed). */
  confirmedQuantity?: Maybe<Scalars['Int']['output']>;
  /** Defines variant size count. */
  count?: Maybe<Scalars['Int']['output']>;
  /** Defines quantity (delivered). */
  deliveredQuantity?: Maybe<Scalars['Int']['output']>;
  /** Defines discount unit value. */
  discount?: Maybe<OrderItemPrice>;
  /** Defines discount percentage. */
  discountPercentage?: Maybe<Scalars['Float']['output']>;
  /** Defines gross price. */
  priceGross?: Maybe<OrderItemPrice>;
  /** Defines net price. */
  priceNet?: Maybe<OrderItemPrice>;
  /** Defines RRP price. */
  priceRrp?: Maybe<OrderItemPrice>;
  /** Defines quantity (requested). */
  requestedQuantity?: Maybe<Scalars['Int']['output']>;
  /** Defines variant size title. */
  title?: Maybe<Scalars['String']['output']>;
  /** Defines quantity (unconfirmed). */
  unconfirmedQuantity?: Maybe<Scalars['Int']['output']>;
  /** Defines discount value. */
  unitDiscount?: Maybe<OrderItemPrice>;
  /** Defines unit gross price. */
  unitPriceGross?: Maybe<OrderItemPrice>;
  /** Defines unit net price. */
  unitPriceNet?: Maybe<OrderItemPrice>;
  /** Defines unit RRP price. */
  unitPriceRrp?: Maybe<OrderItemPrice>;
  /** Defines variant size width. */
  width?: Maybe<Scalars['String']['output']>;
};
